
import Link from 'next/link';
import { useRouter } from 'next/router';
import { IoIosArrowForward } from "react-icons/io";

// HI icons
import { HiOutlineX } from "react-icons/hi";
import { HiOutlineHome } from "react-icons/hi";
import { HiOutlineClipboardList } from "react-icons/hi";
import { HiOutlineHeart } from "react-icons/hi";
import { HiOutlineCash } from "react-icons/hi";
import { HiOutlineCog } from "react-icons/hi";
import { HiOutlineTranslate } from "react-icons/hi";
import { HiOutlineChevronRight } from "react-icons/hi";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";

import { DisplayProfilePicture } from '@/src/atoms-v3/image/DisplayProfilePicture';

//hooks
import { useLogout } from '@/src/hooks/useLogout';
// import { userLogout } from '@/store/slices/userSlice';

//redux
import { useAppSelector } from '@/src/hooks/useSelector';
import { useAppDispatch } from '@/src/hooks/useDispatch';


import { SimpleButton } from '@/src/atoms-v3/button/SimpleButton';

interface UserMainMenuProps {
    is_left: boolean;
    hideHandler: () => void;
    goToLanguageMenu: () => void;
}

export const UserMainMenu = ({
    is_left=true,
    hideHandler,
    goToLanguageMenu
}:UserMainMenuProps) => {
    const logout = useLogout();
    const router = useRouter();
    
    //redux state
    const dispatch = useAppDispatch();

    const userData = useAppSelector(state => state.user).data
    const {token, is_organizer, email, profilePicture, username} = userData


    //logout handler
    const logoutHandler = () => {
        logout()
        hideHandler()
    }


    const MenuTabContainer = ({href,children}: {
        href: string;
        children: JSX.Element | JSX.Element[]
    }) => {
        //router.pathname === '/'
        return (
            <li>
                <Link 
                    href={href}
                    tabIndex={-1}
                    className='flex flex-row items-center gap-3 px-6 py-2 cursor-pointer hover:bg-gray-100'
                >
                    {children}
                </Link>
            </li>
        )
    }

    return (
        <div className='w-full h-full flex flex-col gap-3'>
            <div className={`
                w-full flex gap-4 items-center px-3 py-3 border-b
                ${is_left ? 'flex-row-reverse justify-between' : 
                'flex-row justify-start '}
            `}>
                <HiOutlineX 
                    onClick={hideHandler}
                    size={18} tabIndex={-1}
                    className='cursor-pointer'
                />
                <p className='font-bold'>Menu</p>
            </div>

            { token ? (
                <div className='flex flex-row p-3 gap-3 items-center'>
                    <DisplayProfilePicture 
                        height='h-[44px]'
                        image={profilePicture}
                        alt={username}
                        placeholderColor='orange'
                        placeholderLetter={username ? username[0].toUpperCase() : ''}
                    />
                    <p className='font-medium text-sm'>{email}</p>
                </div>
            ): (<></>)}

            { is_organizer ? (
                <div className='px-3 w-full'>
                    <SimpleButton
                        className='w-full'
                        isTabbable={false}
                        onClick={() => router.push('/organizer/home')}
                    >
                        Switch to Organizer
                    </SimpleButton> 
                </div>
            ) : (<> </>)}

            <ul className='flex flex-col w-full gap-0 py-2'>
                <MenuTabContainer href='/'>
                    <HiOutlineHome size={18}/>
                    <p className='text-sm sm:text-base'>Home</p>
                </MenuTabContainer>

                {   token ? (
                    <>
                    <MenuTabContainer href='/user/bookings'>
                        <HiOutlineClipboardList size={18}/>
                        <p className='text-sm sm:text-base'>Bookings</p>
                    </MenuTabContainer>

                    <MenuTabContainer href='/user/purchased-plans'>
                        <HiOutlineCash size={18}/>
                        <p className='text-sm sm:text-base'>Purchased Plans</p>
                    </MenuTabContainer>

                    <MenuTabContainer href='/user/following'>
                        <HiOutlineHeart size={18}/>
                        <p className='text-sm sm:text-base'>Following</p>
                    </MenuTabContainer>
                    <MenuTabContainer href='/user/payments'>
                        <HiOutlineSwitchHorizontal size={18}/>
                        <p className='text-sm sm:text-base'>Payments</p>
                    </MenuTabContainer>
                    <MenuTabContainer href='/settings/profile'>
                        <HiOutlineCog size={18}/>
                        <p className='text-sm sm:text-base'>Settings</p>
                    </MenuTabContainer>
                    </>
                ) : (<></>)
                }

                <li 
                    onClick={() => goToLanguageMenu()}
                    className='flex flex-row items-center gap-3 px-6 py-2 cursor-pointer hover:bg-gray-100'
                >
                    <HiOutlineTranslate size={18}/>
                    <p className='text-sm sm:text-base'>Langauge</p>
                    <HiOutlineChevronRight size={18} className='ml-auto'/>
                </li>
            </ul>
            

            {   !is_organizer ? (
                <Link 
                    tabIndex={-1}
                    href='/join-ambition/organizer'
                    className='px-6 py-2'
                >
                    <p className='text-sm sm:text-base font-medium cursor-pointer hover:underline'>
                        Teach on Ambition
                    </p>
                </Link>
            ):(<> </>)
            }
           

            {   token ? (
                <div className='px-6 py-2'>
                    <p 
                        onClick={logoutHandler}
                        className='text-sm sm:text-base cursor-pointer hover:underline'>
                        Logout
                    </p>
                </div>

            ) : (
                <div className='flex flex-col gap-3 px-6 w-full'>
                <SimpleButton 
                    onClick={() => router.push('/login')} 
                    color='black' bg='outline'
                    className='px-6 py-2 w-full'
                >
                    <p className='font-medium'>
                        Login
                    </p>
                </SimpleButton>

                <SimpleButton 
                    onClick={() => router.push('/signup')} 
                    color='orange' bg='solid'
                    className='px-6 py-2 w-full'
                >
                    <p className='font-medium'>
                        Sign Up
                    </p>
                </SimpleButton>
                </div>
            )}
            
        </div>
    )
}